import { useMutation } from '@apollo/client'
import {
  Core_TransactionType,
  LandingCreateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useTracking } from '@flock/shared-ui'
import {
  AddressData,
  createLeadHelper,
  localStore,
  UserEventType,
} from '@flock/utils'
import { navigate } from 'gatsby'
import { useState } from 'react'
import { ONBOARDING_PATH } from '../../../routeConstants'
import { getEventTokenUUID } from '../../../utils/analytics'

const useHomepageHeroSection = () => {
  const { track } = useTracking()
  const [loading, setLoading] = useState<boolean>(false)
  const [addressData, setAddressData] = useState<AddressData | null>(null)

  const [onboardingLeadUrlParams, setOnboardingLeadUrlParams] =
    useState<string>('')

  const [createLead] = useMutation(LandingCreateLeadDocument)

  let params: URLSearchParams | null = null
  if (typeof window !== `undefined`) {
    params = new URLSearchParams(window.location.search)
  }

  const onSubmit = async () => {
    if (addressData?.streetAddress) {
      setLoading(true)

      try {
        track('button_click', {
          type: 'button',
          leadConversion: 'started',
          ...addressData,
          actionType: UserEventType.BUTTON_CLICK,
        })
        const leadData = {
          addressData,
          source: 'onboarding',
          transactionType: Core_TransactionType.TransactionTypeSingle_721,
          eventTokenUuid: getEventTokenUUID(),
        }
        const queryParams = localStore?.getItem('queryParams')
        let parsedQueryParams: any = {}
        if (queryParams) {
          parsedQueryParams = JSON.parse(queryParams)
        }
        const createLeadResponse = await createLeadHelper(
          leadData,
          parsedQueryParams,
          createLead
        )
        const newLeadUuid = createLeadResponse?.data.createLead.lead.uuid
        let path = `${ONBOARDING_PATH}?leadUuid=${newLeadUuid}`
        if (typeof Storage !== 'undefined') {
          localStore?.removeItem('step')
          localStore?.removeItem('flowData')
        }
        // carry forward all url params to be parsed in the same flow as onboarding flow
        params?.forEach((key, value) => {
          path += `&${value}=${key}`
        })
        navigate(path)
      } catch (e) {
        window.location.href = `https://form.jotform.com/tech700/flock-onboarding?address[addr_line1]=${addressData?.streetNumber}%20${addressData?.streetAddress}&address[city]=${addressData?.city}&address[state]=${addressData?.state}&address[postal]=${addressData?.zipcode}`
        setLoading(false)
      }
    }
  }

  const utmSource = params?.get('utmSource') || params?.get('utm_source')

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      onSubmit()
    }
  }

  return {
    onKeyDown,
    onSubmit,
    utmSource,

    ctaLabel: 'Get an offer',
    loading,
    setLoading,
    setAddressData,
    onboardingLeadUrlParams,
    setOnboardingLeadUrlParams,
  }
}

export default useHomepageHeroSection
